@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  margin: 10% auto;
  max-width: 60%;
  display: flex;
  /* flex-direction: row; */
}
